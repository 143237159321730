import React from "react";

export class Status {
    static SUCCESS = 'success';
    static ERROR = 'error';
    static LOADING = 'loading';
}

export default function AuthResponse({response, status}) {

    return (
        <div>
            {status === Status.SUCCESS ?(
            <div class="bg-green text-green px-4 py-3 rounded relative" role="alert">
                <strong class="font-bold text-white0">Authentication Successful!</strong>
                <span class="relative inline-block text-white0 flex">{response}</span>
                <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                    <svg class="fill-current h-6 w-6 text-white0" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <title>Close</title>
                        <path d="M6.293 6.293a1 1 0 011.414 0L10 10.586l2.293-2.293a1 1 0 111.414 1.414L11.414 12l2.293 2.293a1 1 0 01-1.414 1.414L10 13.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 12 6.293 9.707a1 1 0 010-1.414z" clip-rule="evenodd" fill-rule="evenodd" />
                    </svg>
                </span>
            </div>)
            :
            (
            status === Status.LOADING ? (
            <div class="bg-yellow-600 text-green px-4 py-3 rounded relative" role="alert">
                <strong class="font-bold text-white0">Loading...</strong>
                <span class="relative inline-block text-white0 flex">{response}</span>
                <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                    <svg class="fill-current h-6 w-6 text-white0" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <title>Close</title>
                        <path d="M6.293 6.293a1 1 0 011.414 0L10 10.586l2.293-2.293a1 1 0 111.414 1.414L11.414 12l2.293 2.293a1 1 0 01-1.414 1.414L10 13.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 12 6.293 9.707a1 1 0 010-1.414z" clip-rule="evenodd" fill-rule="evenodd" />
                    </svg>
                </span>
            </div>
            )
            :
                <div class="bg-red-600 text-green px-4 py-3 rounded relative" role="alert">
                <strong class="font-bold text-white0">Authentication Failed!</strong>
                <span class="relative inline-block text-white0 flex">Error: {response}</span>
                <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                    <svg class="fill-current h-6 w-6 text-white0" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <title>Close</title>
                        <path d="M6.293 6.293a1 1 0 011.414 0L10 10.586l2.293-2.293a1 1 0 111.414 1.414L11.414 12l2.293 2.293a1 1 0 01-1.414 1.414L10 13.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 12 6.293 9.707a1 1 0 010-1.414z" clip-rule="evenodd" fill-rule="evenodd" />
                    </svg>
                </span>
                </div>
            )
        }
        </div>
    )
}

