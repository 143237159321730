import React from 'react';
import useFacebookAuth from '../../Api/useFacebookAuth';
import AuthResponse, { Status } from '../../Components/AuthResponse/Index';



const FacebookAuth = () => { 

    const facebookAuth = useFacebookAuth();
    const [response, setResponse] = React.useState('')
    const [status, setStatus] = React.useState(Status.LOADING)

    const currentUrl = window.location.href;
    const urlObj = new URL(currentUrl);
    const searchParams = new URLSearchParams(urlObj.search);
    const authorization_code = searchParams.get("code");
    const error_reason = searchParams.get("error_reason");
    const error = searchParams.get("error");

    React.useEffect(() => {
        async function getCredentials() {
            if(!authorization_code) {
                setResponse(`No authorization code received${error_reason? '. Reason: ' + error_reason: ''}`);
                setStatus(Status.ERROR)
                return;
            }
            if(error) {
                setResponse(error);
                setStatus(Status.ERROR)
                return;
            }
            const pfs = await facebookAuth.getAccessToken(authorization_code)
            if(!pfs.success) {
                setStatus(Status.ERROR)
                pfs.error && setResponse(`${pfs.error}`);
                return;
            }
            else {
                setStatus(Status.LOADING)
                setResponse('Storing credentials')
                facebookAuth.storeCredentials(`${pfs.access_token}`).then(response => {
                    if(response.success) {
                        setStatus(Status.LOADING)
                        setResponse('Synchronizing ad accounts')
                        facebookAuth.syncAdAccounts().then(response => {
                            if(response.success) {
                                setStatus(Status.SUCCESS)
                                setResponse(`Facebook account connected successfully. ${response.data} ad accounts synced.`)
                            }
                            else {
                                setStatus(Status.ERROR)
                                setResponse(response.error)
                            }
                        })
                    }
                    else {
                        setStatus(Status.ERROR)
                        setResponse(response.error)
                    }
                })
            }
        }
        getCredentials();

    }, [])

    return (
        <>
            <AuthResponse response={response} status={status} />
        </>


    );
}

export default FacebookAuth;