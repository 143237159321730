import { useAuth } from '../Hooks/useAuth';
import { n2api } from './n2api';
import axios from 'axios';

const useFacebookAuth = () => {

    const { token } = useAuth();

    const getAccessToken = async (authorization_code) => {
        const redirect_uri = process.env.REACT_APP_FACEBOOK_REDIRECT_URI;
        return n2api.post(`/facebook/auth`, {
            code: authorization_code,
            redirect_uri: redirect_uri
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
                return {
                    success: false,
                    response: {},
                    error: error
                }
            });
    }

    const storeCredentials = async (accessToken) => {
        const createCredentialDto = {
            accessToken: accessToken,
        };

        // Make a POST request to the n2api backend to store the refresh token
        return n2api.put(`/facebook/credentials`, createCredentialDto, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then((response) => {
                if (response.status === 200 || response.status === 201 || response.status === 302) {
                    return {
                        success: true,
                        data: response.data
                    }
                } else {
                    return {
                        success: false,
                        response: response,
                        error: new Error(response.statusText)
                    }
                }
            })
            .catch((error) => {
                console.error('Error storing Facebook credentials:', error);
                return {
                    success: false,
                    response: {},
                    error: error
                }
            });
    }

    const syncAdAccounts = async () => {
        return n2api.put(`/facebook/adaccounts`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then((response) => {
            console.log('Ad accounts synced successfully');
            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data
                }
            } else {
                return {
                    success: false,
                    response: response,
                    error: new Error(response.statusText)
                }
            }
            // Handle the response as needed
        })
        .catch((error) => {
            console.error('Error syncing ad accounts:', error);
            return {
                success: false,
                response: {},
                error: error
            }
        });
    }

    const disconnectFacebook = async () =>  {
        return n2api.delete(`/facebook/credentials`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then((response) => {
                console.log('Disconnected from Facebook successfully');
                if (response.status === 200) {
                    return {
                        success: true,
                        data: response.data
                    }
                } else {
                    return {
                        success: false,
                        response: response,
                        error: new Error(response.statusText)
                    }
                }
                // Handle the response as needed
            })
            .catch((error) => {
                console.error('Error disconnecting from Facebook:', error);
                // Handle the error
            });
    }

    const isConnected = async () =>  {
        return n2api.get(`/facebook/credentials/isConnected`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then((response) => {
                // Handle the response as needed
                return response.data.isConnected
            })
            .catch((error) => {
                console.error('Error checking facebook connection status:', error);
                // Handle the error
            });
    }

    return {
        getAccessToken,
        storeCredentials,
        syncAdAccounts,
        disconnectFacebook,
        isConnected
    }

}

export default useFacebookAuth;