import React from 'react';
import useGoogleAdsAuth from '../../Api/useGoogleAdsAuth';
import AuthResponse from '../../Components/AuthResponse/Index';
import useGoogleAdsAdAccounts from '../../Api/useGoogleAdsAdAccounts';
import { Status } from '../../Components/AuthResponse/Index';


const GoogleAdsAuth = () => {

    const googleAdsAuth = useGoogleAdsAuth();
    const googleAdAccounts = useGoogleAdsAdAccounts();
    const [response, setResponse] = React.useState('')
    const [status, setStatus] = React.useState(Status.LOADING)
    
    const currentUrl = window.location.href;
    const urlObj = new URL(currentUrl);
    const searchParams = new URLSearchParams(urlObj.search); 
    const code = searchParams.get("code");
    
    
    
    React.useEffect(() => {
        async function getAccessToken() {
            const pfs = await googleAdsAuth.getAccessToken(code)
            if(!pfs.success) {
                setStatus(Status.ERROR)
                setResponse(pfs.error);
                return;
            }
            
            if(pfs.success){
                setResponse("Access token received successfully")
                const tokenStoredSuccessfully = await googleAdsAuth.storeCredentials(pfs.access_token, pfs.data.refresh_token)
                if(tokenStoredSuccessfully){
                    setResponse("Credentials stored successfully")
                    const adAccountsUpdated = await googleAdAccounts.updateAdAccounts()
                    if(adAccountsUpdated){
                        setResponse("Google Ads connected successfully")
                        setStatus(Status.SUCCESS)
                    } else {
                        setResponse("Error updating ad accounts")
                        setStatus(Status.ERROR)
                    }
                } else {
                    setResponse("Error storing credentials")
                    setStatus(Status.ERROR)
                }
            }   else {
                setResponse(`${pfs.error}`)
                setStatus(Status.ERROR)
            }
        }
        getAccessToken();
        
    }, [])
    
    return (
        <>
        <AuthResponse response={response} status={status} />
        </>
        
        
        );
    }
    
    export default GoogleAdsAuth;